/* ==========================================================================
    [9. CUSTOM]
========================================================================== */

body {
  font-family: Montserrat;
}

.navigation.sidebar-left .collapse,
.collapsing {
  position: relative;
  z-index: 1000;
}

.navigation .nav-link {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
}

$big-mockup-width: 920px;

p {
  margin: 1rem 0;
}

.logo {
  max-width: 180px;
}

.navigation.navbar-sticky .logo.logo-sticky {
  max-height: 45px;
}

.navigation.navbar-sticky {
  min-height: 70px;
}

.swiper-container {
  .swiper-pagination-top {
    bottom: auto;
    top: 0;
  }
}

hr[class*="bg"] {
  border-top: 0;
  height: 1px;
}

.off-left-background {
  background-position: -60px 20px;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s ease;

  &:hover {
    background-position: -60px 0px;
    transform: translate3d(0, -10px, 0);
  }
}

.nav-process {
  min-height: 60px;
}

/** custom **/

.delegate-page .modal,
.delegate-page .modal-backdrop {
  pointer-events: none;
}

.delegate-cta {
  float: right;
}

#features {
  text-align: center;
  transform: translateY(-40px);
}

.delegate-cta a {
  background: #ffffff;
  border-radius: 7px;
  margin-top: 6px;
  padding: 10px !important;
  font-weight: 600 !important;
  color: #85357e !important;
}

.navbar-sticky .delegate-cta a {
  background-image: linear-gradient(180deg, #85357e 0, #163075 100%);
  color: #ffffff !important;
}

.icon {
  display: flex;
}

.navigation .logo {
  max-height: 60px;
}

.site-footer .logo {
  margin: 7px 10px 0px 0px;
}

.bloc-desc-footer {
  display: flex;
}

.text-third {
  color: #aa5da3;
}

.logo-min {
  width: 22px;
  transform: translateY(-1px);
  margin-right: 3px;
}

.btn-standard {
  background: #3a127a;
  color: #ffffff;
}

.btn-standard:hover {
  color: #ffffff;
  box-shadow: 0 0px 15px rgba(255, 255, 255, 0.4) !important;
  border-color: #c6c7e6;
}

.navbar-brand {
  padding-top: 0px;
}

.navigation.navbar-sticky .nav-link {
  color: #70549e;
}

.navbar .container {
  padding: 0px;
}

.gradient.gradient-primary-light {
  background-image: linear-gradient(180deg, #aa5da3 0, #2b4898 100%);
}

.bg-form {
  background-image: url("../../img/Best-FTSO-background-small.jpg");
  background-size: cover;
}

.bg-form h2 {
  color: #ffffff;
}

.bg-form .heading-line::after {
  background-color: #ffffff;
}

.text-form {
  color: #ffffff;
  font-weight: 500 !important;
}

.bg-form .form-wrapper {
  width: 100%;
}

.form-subscribe label {
  color: #fff;
  margin-left: 15px;
  font-weight: 400;
}

.form-subscribe .btn {
  height: 45px;
}

.form-group input {
  border: 2px solid #2d219931;
  color: #222222 !important;
  border-radius: 8px;
}

.btn {
  border-radius: 8px;
}

.btn-delegate {
  margin-top: 12px;
  border-radius: 20px;
  background-image: linear-gradient(180deg, #85357e 0, #163075 100%);
  color: #fff;
  font-weight: 600;
  border: none;
  line-height: 20px;
  border-radius: 8px;
  border: 2px solid #fff;
  text-align: center;
  color: #ffffff;
  font-weight: 600;
}

.section-heading {
  margin-bottom: 40px;
}

.btn-delegate:hover {
  color: #ffffff;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.315);
}

.rounded {
  border-radius: 5.25rem !important;
}

.text-center {
  text-align: center;
}

.ui-video-player-component {
  min-height: 418px !important;
  border-radius: 10px;
  overflow: hidden;
  margin: 20px 0px;
}

.questModal h2 {
  margin: 20px 0px 20px 0px;
  font-size: 25px;
}

.questModal h3 {
  margin: 1px 0px 10px 0px;
  font-size: 18px;
  font-weight: 500;
}

.questModal .modal-header {
  align-items: center;
  display: inline-block;
}

h4.text-center {
  text-align: center;
  display: block;
}

.fullNFT div {
  width: auto !important;
  height: auto !important;
}

.fullNFT video {
  margin: 0 auto;
  height: 100% !important;
  max-width: 100% !important;
  width: auto !important;
  margin-top: 12px;
  border-radius: 10px;
}

#features {
  display: inline-block;
  width: 100%;
  z-index: 100;
}

.pt-100 {
  padding-top: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.faq-container {
  display: block;
}

.collapse .p-3 {
  padding-top: 0px !important;
}

.price-row {
  color: #333;
  border-radius: 20px;
  border: none;
  margin-bottom: 7px;
  overflow: hidden;
}

.price-row .col-5 {
  background-image: linear-gradient(180deg, #85357e 0, #163075 100%);
  color: #fff;
  text-align: center;
  padding: 10px;
}

.price-row .col-7 {
  background-image: linear-gradient(180deg, #ffffff 0, #dce0ee 100%);
  color: #333;
  font-weight: 600;
  text-align: center;
  padding: 10px;
}

.bg-light-second.edge.top-left::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(237,244,248)' fill-opacity='0.1'%3E%3Cpolygon points='0,310 100,310 100,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: "";
  height: 310px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  top: -310px;
}

.bg-light-second {
  background: #ffffff18;
}

.heading-line::after {
  background-color: #ffffff;
}

.card,
.faqs-dd .card-header {
  border-radius: 8px;
}

.response-message {
  text-align: center;
  color: #ffffff;
}

.response-message .fa-envelope {
  color: #ffffff;
  font-size: 30px;
}

.center-main {
  max-width: 1180px;
  margin: 0 auto;
}

.balance {
  border: 1px solid #2f1e8533;
  border-radius: 6px;
  padding: 0px;
}

.balance .inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.reload-btn {
  padding: 4px 10px 6px 10px !important;
  margin: 10px 10px 0px 0px;
}

.main-column-balance.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.eligible {
  color: #994892;
  border: 1px solid #863f80;
  background-color: #ffffff;
  transform: translateY(0px) translateX(5px);
  position: absolute;
  border-radius: 4px;
  letter-spacing: 0.5px;
  padding: 2px 10px;
  font-size: 12px;

  /*   transform: rotate(-7deg) translateY(-10px) translateX(-20px);  */
}

.delegated-percent {
  background-image: linear-gradient(180deg, #f5eff4 0, #ccc1cb 100%);
  border-top: 1px solid #e6dce7;
  color: #3e2d8a;
  border-radius: 0px 0px 3px 3px;
  padding: 7px 5px 5px;
}

.balance h5 {
  color: #3e2d8a;
  background-color: #f1ebf0;
  border-radius: 3px 3px 0px 0px;
  border-bottom: 1px solid #cbc7ce;
  padding: 5px 10px 7px;
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 600;
}

.balance h4 {
  cursor: default;
  color: #3e2d8a;
  margin-bottom: 6px;
  padding: 8px 8px 3px;
  font-family: Montserrat;
  line-height: 30px;
}

.balance h4 img {
  transform: translateY(-1px);
  margin-left: 6px;
}

.delegated-percent h3 {
  font-size: 14px !important;
  color: #3e2d8a;
  margin: 6px 10px;
  float: left;
}

.balance h6 {
  font-weight: 800;
  margin-left: 1px;
  color: #3e2d8a !important;
  background: #ffffff;
  border: none !important;
  border-radius: 4px;
  font-size: 14px;
  padding: 1px 6px 3px 6px;
  display: inline-block;
  clear: both;
  margin-bottom: 3px;
  margin-right: 3px;
}

.balance h6 span {
  font-weight: 500;
}

.balance .rate {
  color: #444444;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 400;
}

.zero-balance {
  opacity: 0.5;
  filter: grayscale(100);
}

.pl-20 {
  padding-left: 20px;
}

.body-assets {
  color: #444444;
}

.table-head {
  border-bottom: 2px solid rgb(238, 236, 236);
  margin-bottom: 10px;
}

.lead {
  margin-top: 0px !important;
}

.NFTcover-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 308px;
}

.NFTcover-container a,
.NFTcover-container div {
  display: flex;
  flex-flow: column nowrap;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.NFTcover-container div img {
  height: auto;
  max-height: 100%;
  max-width: 100%;
}

.nft-row {
  margin-bottom: 35px;
}

.nft-club-card {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 7px 12px;
  background-image: linear-gradient(180deg, #85357e 0, #163075 100%);
}

.darkCard {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 11px 12px 20px 12px;
  background: #3a127a;
  background-image: linear-gradient(180deg, #6f35a5 0, #360f57 100%);
  min-height: 21vh;
}

.darkCard h4 {
  margin: 0px !important;
  color: #ffffff;
  padding: 0px !important;
}

.darkCard p {
  padding: 0px !important;
  margin: 0px !important;
  color: #ffffff !important;
  font-size: 15px;
}

.nft-club-card h5 {
  margin: 0px;
}

.nft-club-card p {
  margin: 0px 0px 10px 0px;
  font-size: 13px;
}

.nft-club-card p span {
  color: #163075;
  background: rgba(255, 255, 255, 0.863);
  padding: 0px 5px;
  font-weight: 600;
  border-radius: 3px;
}

.btn-delegate-asset,
.btn-swap {
  background-image: linear-gradient(180deg, #85357e 0, #163075 100%);
  color: #fff;
  font-weight: 600;
  border: none;
  padding: 5px 6px;
  line-height: 20px;
  border-radius: 8px;
  border: 2px solid #fff;
  text-align: center;
}

.btn-gradient {
  background-image: linear-gradient(180deg, #85357e 0, #163075 100%);
  color: #fff;
  font-weight: 600;
  border: none;
  padding: 5px 6px;
  line-height: 20px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #7f62a5;
  margin-top: 11px;
}

.btn-gradient:hover {
  color: #ffffff;
}

.redeem-unwrap {
  background-image: linear-gradient(-90deg, #6d636b 0, #3f434e 100%) !important;
}

.modal-footer .btn {
  padding: 4px 10px !important;
  line-height: 25px !important;
}

.modal-footer div:last-child .btn {
  margin-left: 10px;
}

.modal-footer .redeem-unwrap {
  margin-right: 10px !important;
}

.action-col {
  padding-right: 0px;
}

.btn-delegate-asset {
  margin: 0px 4px 0px 0px;
}

.btn-delegate-asset:hover,
.btn-swap:hover {
  box-shadow: 0 0px 8px rgba(162, 72, 204, 0.692) !important;
  color: #ffffff;
}

.wrapped-asset-line,
.asset-line {
  margin: 9px -8px;
  background: #f3f3f3;
  border: 1px solid rgba(221, 225, 230, 0.575);
  border-radius: 5px;
}

.asset-name {
  padding: 0px 15px;
}

.wrapped-asset-line .asset-name {
  display: inline-block;
  padding: 17px 0px 0px 15px;
}

.icon-btn {
  margin-right: 3px;
}

.btn-reload {
  background-image: linear-gradient(0deg, #6d636b 0, #3f434e 100%);
  color: #fff;
  margin-top: 0px;
  padding: 2px 5px;
  border-radius: 4px;
  letter-spacing: 0px;
  font-weight: 600;
  border: 1px solid #fff;
  float: right;
}

.btn-hide-balance {
  border: none;
  color: #333;
  margin-right: 5px;
  padding: 0px 0px 0px 5px;
  border-radius: 7px;
  letter-spacing: 0px;
  font-weight: 600;
  border: 1px solid rgb(230, 230, 230);
  transition: all 0.3s ease;
}

.btn-hide-balance:hover {
  border: 1px solid #d8c0d6;
  background-image: linear-gradient(0deg, #e7e7e7 0, #f8f8fa 100%);
  color: #333;
}

.btn-hide-balance img {
  filter: invert(0);
  margin: 1px 6px 2px 2px;
}

.btn-hide-balance:hover img {
  filter: invert(0);
}

.delegated {
  background: #ffffff;
  color: #3e2d8a;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  clear: both;
  border: 1px solid #3e2d8a49;
  padding: 3px 8px;
}

.scrollZone {
  background: #fefefe1f;
  border-radius: 10px;
}

.scrollZone div {
  padding: 6px;
}

.scrollZone div div div {
  padding: 0px;
}

.nftpclaim {
  padding: 5px 15px 2px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
  justify-content: space-between;

}

.clmbtn {
  border-radius: 5px !important;
  cursor: pointer;
  padding: 3px 3px !important;
}

@media screen and (max-width: 576px) {

  .sale-details-cleared {
    clear: both;
    height: 900px;
  }

  .react-tabs__tab-list {
    padding: 0px 10px !important;
  }

  .colNFT {
    margin: 0px !important;
    padding: 0px 10px !important;
  }

  .outerNFT {
    padding: 0px !important
  }
}

.transaction-block {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.left-block-transaction {
  padding: 5px 1.5em;
}

.left-block-transaction h5 {
  margin-bottom: 15px;
  color: #9a56a5;
}

.scrollZone div.transaction {
  padding: 6px 10px;
}

.form-group .row {
  margin-bottom: 10px;
}

.transaction {
  cursor: default;
  display: flex;
  background: #f3f3f3;
  border: 1px solid #dde1e6;
  border-radius: 8px;
  padding: 6px 15px;
  width: calc(100% - 10px);
  margin-bottom: 6px;
}

.underDev {
  padding: 15vh 10vw !important;
}

.explorerLink {
  cursor: pointer;
  padding: 0px !important;
  margin: 0px 5px !important;
}

.storeTitle {
  font-size: 19px;
}

.transaction img {
  opacity: 0.8;
  margin: 0px 10px;
}

.transaction-content {
  font-weight: 700;
  color: #4c277c;
  line-height: 22px;
}

.spaceBetween {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.spaceBetween .what {
  line-height: 30px;
}

.what span {
  font-size: 13px;
  margin-left: 5px !important;
}

.transaction-content span {
  margin-left: 8px;
  font-weight: 300 !important;
  color: #444;
}

.dropdown-menu.listingDropList {
  padding: 1px !important;
  border: 1px solid #3a127a2a !important;
}

.listingDropList button {
  border-radius: 5px;
}

.who,
.when {
  font-size: 13px;
  font-weight: 400;
}

.alignLabel {
  padding-top: 8px;
}

/** Modals **/

.modal-dialog {
  margin: 10rem auto;
}

.largeModal.modal-dialog {
  max-width: 800px;
  margin: 10rem auto;
}

.modal-body {
  min-height: 100px;
  padding: 7px 30px 20px 30px;
}

.small-margin .col-3 {
  padding-left: 5px;
  padding-right: 5px;
  flex: 0 0 24%;
  max-width: 24%;
}

.corporate .modal-header {
  background-color: #e9f0f1 !important;
  background-size: cover !important;
  background-position: center right !important;
}

.corporate .modal-footer {
  background-color: #e9f0f1 !important;
  background-size: cover !important;
}

.modal-dialog .btn-primary {
  line-height: 22px;
  margin: 0px;
}

.modaldetails .left {
  font-weight: 400;
  font-size: 16px;
  font-weight: 600;
  padding-left: 6px !important;
}

.modaldetails .right {
  font-weight: 400;
  float: right;
  font-size: 17px;
}

.smallModal.modal-dialog {
  max-width: 400px;
  margin: 5rem auto;
}

.modal-body .asset {
  font-size: 20px;
  line-height: 36px;
}

.Modal .modal-content {
  background-size: cover;
  background-position: center center;
  border: 5px solid #cadeec80;
  border-radius: 12px;
}

.darkModal .modal-content {
  color: #fff;
  background-image: linear-gradient(180deg, #85357e 0, #163075 100%);
  background-size: cover;
  background-position: center center;
  border: 1px solid #3ea1ec;
  border-radius: 12px;
}

.title-up span {
  font-weight: 300;
}

.modal-footer {
  justify-content: space-between;
}

.amount-adjustment {
  margin: 20px -15px;
}

.assets-column {
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
}

#percent {
  background-color: #fff;
  font-weight: 600;
  padding: 10px;
  width: 80%;
  float: right;
}

.darkModal .modal-content label {
  color: #fff;
}

.Modal .modal-footer,
.Modal .modal-header {
  background-color: #f1ebf0;
  border-color: #30518a;
  padding: 0.7rem 0.65rem;
}

.Modal .modal-header {
  border-bottom: 1px solid rgb(203, 199, 206);
}

.Modal .modal-footer {
  border-top: 1px solid rgb(203, 199, 206);
}

.darkModal .modal-footer,
.darkModal .modal-header {
  background-color: #14182e;
  border-color: #30518a;
  padding: 0.7rem 0.65rem;
}

.Modal .modal-footer .btn {
  font-weight: 600;
  color: #fff;
  border: none;
}

.modal-header {
  border-radius: 12px 12px 0px 0px;
}

.modal-footer {
  border-radius: 0px 0px 12px 12px;
}

.Modal .btn-danger {
  background-image: linear-gradient(-180deg, #85357e 0, #163075 100%);
  box-shadow: none !important;
  padding: 7px 20px;
  margin: 0px;
  line-height: 22px;
  font-weight: 600;
  border-color: #913a3a;
}

.Modal .btn-danger:hover {
  background-color: #cc0a0a;
}

.darkModal .btn-danger {
  background-color: #ff667c;
  padding: 7px 20px;
  margin: 0px;
  line-height: 22px;
  font-weight: 600;
  border-color: #ea0606;
  border-radius: 10px;
}

.modal-header .close {
  color: #ffffff;
  opacity: 1;
  margin-right: -5px;
}

.available-amount {
  font-size: 1.2em;
  font-weight: 200;
  margin-right: 10px;
}

.available-amount strong {
  font-weight: 600 !important;
}

.darkModal .btn-round {
  margin: 0px;
  line-height: 25px;
}

.btn-default {
  font-weight: 600;
  background-color: #14182e;
  box-shadow: 0px 0px 3px rgba(162, 72, 204, 0.692);
  color: #ffffff;
}

.btn-default:hover {
  color: #ffffff;
}

.modal-footer .btn-default {
  background-image: linear-gradient(180deg, #85357e 0, #163075 100%);
  box-shadow: 0 0px 3px rgba(202, 225, 238, 0.151) !important;
}

.modal-footer .btn-danger {
  box-shadow: 0px 0px 3pxrgba (244, 66, 54, 0.4) !important;
}

.btn-default.level2 {
  background-color: #471847;
  box-shadow: 0px 0px 3px rgba(162, 72, 204, 0.692);
}

.btn-default.level3 {
  background-color: #631328;
  box-shadow: 0px 0px 3px rgba(162, 72, 204, 0.692);
}

.btn-default.level4 {
  background-color: #9b0d38;
  box-shadow: 0px 0px 3px rgba(162, 72, 204, 0.692);
}

/* 
 .form-control {
    background-color: #0d1c36;
    border-color: #14182e;
    color: #fff;
} */

.modal-header h4 {
  margin-bottom: 0px !important;
}

.darkModal .modal-header h4 {
  color: #fefefe;
  margin-bottom: 0px !important;
}

.align-right {
  background-color: #003663 !important;
  justify-content: flex-end;
}

.subinput {
  padding: 2px 0px 0px 5px;
  font-size: 12px;
  font-style: italic;
}

.modaldetails {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.modaldetails.small p {
  margin-top: 0px;
  font-size: 13px;
}

.modaldetails.small {
  border-bottom: 1px solid rgba(148, 202, 233, 0.479);
  margin-bottom: 20px;
}

.warning div {
  border: 2px solid #a10c0c;
  border-radius: 6px;
  padding: 10px 15px 14px 15px;
  font-size: 14px;
  color: #a10c0c;
  font-weight: 500;
}

.mediumModal.darkModal .modal-body {
  min-height: 100px !important;
  padding-bottom: 20px;
}

.mediumModal.modal-dialog {
  max-width: 650px;
  margin: 4rem auto;
}

/** NFT **/

.nft-quest .Title,
.rewardsblock .Title,
.nft-quest .questTitle {
  position: absolute;
  background: #fff;
  border-radius: 40px;
  color: #14182e;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 15px;
  left: calc(50% - 89px);
  transform: translateY(-25px);
  font-family: Montserrat;
  letter-spacing: 0px;
  z-index: 1000;
}

.claim-rewards .rewardsblock .Title {
  left: calc(50% - 90px);
}

.claim-rewards.nftclub .rewardsblock .Title {
  left: calc(50% - 82px);
}

.nft-quest .Title {
  left: calc(50% - 80px);
}

.rewardsblock,
.nft-quest {
  padding: 10px;
  z-index: 1000;
  cursor: default;
}

.NFTcover-container {
  cursor: pointer;
}

.nft-quest {
  margin-bottom: 3vh;
}

.rewardsblock .btn-standard,
.nft-quest .btn-standard {
  letter-spacing: 1px;
  background-image: linear-gradient(180deg, #85357e -1%, #163075 100%);
  font-weight: 600;
  padding: 6px 0px;
  border: none;
}

.react-tabs .nft-quest.card {
  transition: all 0.3s linear;
  border: none;
  background: #ffffffcc;
  border-radius: 7px !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.nft-quest.card img {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.rewardsblock,
.nft-quest.card.owned {
  background-image: linear-gradient(180deg, #85357e -1%, #163075 100%);
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.4);
}

.react-tabs .card.nft-quest:hover {
  background: #ffffffe5;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.4);
}

.nbrOwned {
  transition: all 0.3s linear;
  border-radius: 5px;
  font-size: 11px;
  padding: 0 10px;
  background: hsla(0, 0%, 98.8%, 0.99) !important;
  font-weight: 600;
  line-height: 26px;
  pointer-events: none;
  right: 16px;
  top: 16px;
  color: #4c277c;
}

.nft-quest.card.owned:hover {
  background-image: linear-gradient(180deg, #85357e -1%, #163075 100%);
}

.rewardsblock .btn,
.owned .btn {
  background-image: linear-gradient(0deg, #ada9df -1%, #e4e7f0 100%);
  color: #2d2199;
  font-weight: 700;
  font-family: Montserrat;
  letter-spacing: 0.5px;
  border: none;
}

.rewardsblock .Title,
.owned .questTitle {
  background: #85357e;
  color: #fff;
}

.rewardsblock .Title span,
.owned .questTitle span {
  color: rgb(250, 161, 250);
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.563);
}

.nft-quest img {
  border-radius: 7px;
}

.quest-list {
  margin-top: 14px;
  color: #111;
}

.quest-list .item {
  cursor: pointer;
  padding: 10px;
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(221, 225, 230, 0.575);
  border-radius: 6px;
  background: #f3f3f3;
  margin-bottom: 6px;
}

.quest-list .item img {
  max-height: 60px;
}

.quest-list .item p {
  margin: 0px;
  font-size: 14px;
}

.quest-list .item h6 {
  margin: 8px 0px 0px 0px;
  font-size: 17px;
}

.quest-description {
  margin: 0px 0px 0px 10px;
}

.transfer-nft {
  display: flex;
  flex-direction: column;
  border: 2px solid #fff;
  border-radius: 12px;
  padding: 10px;
}

.transfer-nft .btn {
  width: 100%;
  text-align: center;
  margin-top: 5px;
}

.transfer-nft h2 {
  margin: 0px 0px 5px 0px;
  font-size: 14px;
  font-weight: 500;
}

.transfer-nft h2 span {
  font-weight: 900;
}

.transfer-nft label {
  margin: 0px;
  font-size: 12px;
}

.nft-disclaimer {
  margin: 5px;
  font-size: 12px;
}

.claim-rewards {
  margin-bottom: 2vh;
}

.claim-rewards.nftclub {
  margin-top: 2.5vh;
  margin-bottom: 3vh;
}

.bloc-reward-balance {
  padding: 23px 0px 12px;
  font-weight: 600;
  background-color: #fff;
  border-radius: 6px 6px 0px 0px;
  color: #3e2d8a;
  text-align: center;
}

.homePromo {
  max-height: 500px;
}

.bloc-reward-balance span {
  border-radius: 21px;
  background-image: linear-gradient(180deg, #85357e 0, #163075 100%);
  padding: 2px 12px;
  font-weight: 600;
  color: #ffffff;
}

.btn-reward {
  border-radius: 0px 0px 6px 6px;
  letter-spacing: 0.5px !important;
  font-size: 15px;
  opacity: 0.5;
  border-top: 1px solid #bcb9c4 !important;
}

.btn-reward.rewards-available {
  opacity: 1;
  background-image: linear-gradient(180deg, #85357e 0, #163075 100%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.4);
  border: 1px solid #ddd;
  color: #ffffff;
}

.remain {
  font-weight: 600;
  color: #3e2d8a;
}

.asset-balance {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 35px;
}

.asset-balance .asset {
  display: flex;
  flex-direction: row;
}

.modaldetails .right strong {
  font-weight: 500;
  font-size: 13px;
}

.delegate-header {
  margin-top: 20px !important;
}

.transfer-nft {
  margin-top: 10px;
}

/** mobile **/

@media screen and (max-width: 768px) {
  #percent {
    width: 100%;
    margin-top: 15px;
  }

  .amount-adjustment .col-3 {
    margin: 0px !important;
    padding: 0px !important;
    text-align: center;
  }

  .amount-adjustment .row {
    padding: 0px 20px;
  }

  .amount-adjustment button {
    padding: 10px 15px;
    width: 95%;
    text-align: center;
  }

  .modal-body {
    padding: 10px 20px;
  }

  .modal-body p {
    font-size: 15px;
  }

  .modaldetails .left,
  .modaldetails .right {
    font-size: 13px;
  }

  .modal-header h4,
  .available-amount {
    font-size: 16px;
  }

  .asset {
    vertical-align: middle;
  }

  .delegated-percent h3 {
    margin: 0px 0px 4px 0px;
    float: none;
  }

  .action-col {
    display: flex;
    flex-direction: row;
    padding: 0px;
    text-align: right;
  }

  .action-col a {
    border-radius: 0px 0px 5px 5px;
    width: 100%;
    border: none;
    padding: 6px;
    font-size: 16px;
  }

  .action-col a.btn-delegate-asset {
    margin: 0px;
    border-radius: 0px 0px 0px 5px;
  }

  .action-col a.redeem-unwrap {
    border-radius: 0px 0px 5px 0px;
    border-left: 1px solid #ddd;
  }

  .asset-name {
    line-height: 33px;
  }

  .container-fluid {
    overflow: visible !important;
  }

  .wrapped-asset-line,
  .asset-line {
    padding: 0px !important;
    margin-bottom: 20px;
    border: 2px solid rgba(98, 44, 109, 0.459);
  }

  .wrapped-asset-line .asset-balance,
  .asset-line .asset-balance {
    padding: 8px !important;
  }

  .navigation {
    min-height: 60px;
  }

  .card-body {
    padding: 0px;
  }

  .body-assets {
    padding: 0px 15px;
  }

  .navigation.fixed-top+main .header {
    padding-top: 45px;
  }

  .asset-name {
    font-weight: 600;
    font-size: 14px;
  }

  .container-fluid {
    padding: 0px 6px;
  }

  .logo-sticky {
    width: 40px;
  }

  .prices-row,
  .pr-100 {
    padding: 0px 20px;
  }

  .btn-contrast {
    margin-bottom: 10px;
  }

  .navigation .nav-link,
  .navigation .nav-link {
    background-image: linear-gradient(180deg, #ffffff -1%, #ffffff 100%);
    border-radius: 10px;
  }

  .navigation .nav-link.active,
  .navigation .nav-link:hover {
    background-image: linear-gradient(180deg, #85357e -1%, #163075 100%);
    border-radius: 10px;
    color: #ffffff !important;
  }

  .hide-sm {
    display: none;
  }

  .remain {
    padding-left: 2px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
  }

  .assets-column .text-right {
    text-align: left !important;
    padding-left: 8px;
  }

  .asset-logo {
    transform: scale(0.9);
    transform-origin: top left;
  }

  .asset-name {
    padding: 0px !important;
  }

  .desktop {
    display: none;
  }

  hr,
  .icon-box {
    opacity: 0.5;
  }

  .alter1-header .container {
    padding-bottom: 5rem;
  }

  .pi-title {
    font-size: 40px;
  }

  .main-home h1 {
    margin-bottom: 0px !important;
  }

  .lead {
    font-size: 1em;
    margin-top: 0px !important;
  }

  .navigation.sidebar-left .collapse,
  .collapsing {
    padding-top: 20px;
    left: -300px;
    height: 100vh !important;
    top: 55px !important;
    position: fixed !important;
  }

  .collapse.show {
    left: 0px !important;
    top: 80px !important;
  }

  .margin-mobile {
    margin-bottom: 5vh;
  }

  .site-footer h6,
  .site-footer .nav {
    justify-content: center;
    text-align: center;
  }

  .site-footer p {
    padding: 0px 30px;
  }

  .bloc-desc-footer {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    text-align: justify;
  }

  .site-footer .logo {
    width: 80px !important;
    height: auto !important;
    max-height: 100% !important;
    margin: 0 auto !important;
  }

  .balance {
    margin-bottom: 10px;
  }

  .pl-35 .col-md-4 {
    padding: 0px;
  }

  .pl-35 .row {
    margin: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .nft-page {
    padding-top: 100px !important;
  }

  .hide-small {
    display: none;
  }
}

@media screen and (max-width: 992px) {

  .claim-rewards.nftclub,
  .assets-column,
  .claim-rewards {
    margin-bottom: 4vh;
  }

  .main-home .btn {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-bottom: 14px;
    font-size: 17px;
  }

  .pi-title {
    font-size: 40px;
    line-height: 25px;
  }
}

@media screen and (min-width: 766px) {

  /*   .eligible{
       position: absolute;
       transform: rotate(5deg) translateY(-20px) translateX(30px);
    }
 */
  .mobile {
    display: none;
  }

  .pl-35 {
    padding-left: 30px;
    padding-bottom: 5px;
  }
}

.navigation {
  transition: all 0.2s ease !important;
}

/* Horizontal Stacked Bar Chart */

.graphic {
  height: 30px;
  overflow: hidden;
  border-radius: 8px;
}

@keyframes expand {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  @keyframes expand {
    from {
      width: 0%;
    }

    to {
      width: calc(100% - 75px);
    }
  }
}

.chart {
  overflow: hidden;
  height: 30px;
  width: 100%;
  /*     animation: expand .5s linear ; */
  border-radius: 8px;
}

.block {
  display: block;
  height: 30px;
  color: #fff;
  font-size: 20px;
  float: left;
  background-image: linear-gradient(180deg, #85357e 0, #163075 100%);
  position: relative;
  overflow: hidden;
  opacity: 1;
  transition: opacity, 0.3s ease;
  border: 1px solid #ddd;
  cursor: pointer;
}

.block .value {
  line-height: 20px;
  font-size: 13px;
  top: 5px;
  font-weight: 700;
}

.value span {
  font-weight: 400;
}

.block:nth-of-type(1),
.legend li:nth-of-type(1):before {
  border-right: none;
}

.block:nth-of-type(2),
.legend li:nth-of-type(2):before {
  background-image: linear-gradient(180deg, #c093c2 0, #8385aa 100%);
  border-left: none;
}

.block:nth-of-type(3),
.legend li:nth-of-type(3):before {
  background-image: linear-gradient(180deg, #ffffff 0, #ddd2db 100%);
  color: #333;
  border-radius: 0px 6px 6px 0px;
  border-left: none;
}

.block:nth-of-type(4),
.legend li:nth-of-type(4):before {
  background-color: #e27a3f;
}

.block:nth-of-type(5),
.legend li:nth-of-type(5):before {
  background-color: #df5a49;
}

.block:nth-of-type(6),
.legend li:nth-of-type(6):before {
  background-color: #962d3e;
}

.block:hover {
  opacity: 0.65;
}

.value {
  display: block;
  line-height: 1em;
  position: absolute;
  top: 50%;
  left: 0%;
  width: 100%;
  text-align: center;
}

.x-axis {
  text-align: center;
  padding: 0.5em 0 2em;
}

.y-axis {
  height: 20px;
  transform: translate(-32px, 170px) rotate(270deg);
  position: absolute;
  left: 0;
}

.main-header {
  position: relative;
  z-index: 1000;
  min-height: 440px;
}

.react-tabs__tab {
  border: none !important;
  border-radius: 10px 10px 0 0 !important;
  transform: translateY(-1px);
  font-weight: 300;
  color: #d3a6cd !important;
  background-image: linear-gradient(180deg, #662166 0, #4a2e6e 100%) !important;
  margin-right: 2px;
}

.react-tabs__tab:hover {
  background-image: none !important;
  background: #3b295cda !important;
}

.react-tabs__tab-list {
  border: none !important;
  margin: 0px !important;
}

.react-tabs__tab-panel .nft-row {
  margin: 0px !important;
}

.react-tabs__tab--selected {
  border: none !important;
  border-radius: 10px 10px 0 0;
  transform: translateY(-1px);
  font-weight: 600;
  color: #ffffff !important;
  margin-right: 3px;
  background-image: none !important;
  background: #b173db63 !important;
}

.react-tabs__tab--selected:hover {
  background-image: none !important;
  background: #b173db63 !important;
}

.react-tabs__tab-panel {
  border-radius: 15px !important;
  margin-top: 12px !important;
}

.react-tabs__tab {
  border-radius: 10px !important;
  margin: 3px 2px;
}

// Rotating card

/* entire container, keeps perspective */
.card-container {
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -o-perspective: 800px;
  perspective: 800px;
  margin-bottom: 30px;
}

/* flip the pane when hovered */
.card-container:not(.manual-flip):hover .card,
.card-container.hover.manual-flip .card {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.card-container.static:hover .card,
.card-container.static.hover .card {
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
}

/* flip speed goes here */

.card {
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  -o-transition: -o-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
}

/* hide back of pane during swap */

.front {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
}

.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;

  top: 0;
  left: 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14);
}

.main-back {
  border-radius: 15px;
  padding: 15px 20px;
  overflow: hidden;
  background-image: linear-gradient(180deg, #85357e -1%, #163075 100%);
}

.main-back .text-center {
  position: relative;
  padding: 20px 0px;
  height: 28vh;
}

.back .questTitle {
  transform: translateY(-31px);
  padding: 10px 45px !important;
  left: calc(50% - 94px) !important;
}

/* front pane, placed above back */
.front {
  z-index: 2;
}

/* back, initially hidden pane */
.back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  z-index: 3;
}

.back .btn-simple {
  position: absolute;
  left: 0;
  bottom: 4px;
}

/*        Style       */

.card .footer .social-links {
  font-size: 18px;
}

.card .footer .social-links a {
  margin: 0 7px;
}

.card .footer .btn-simple {
  margin-top: -6px;
}

.card .header {
  padding: 15px 20px;
  height: 90px;
}

.card .motto {
  border-bottom: 1px solid #eeeeee;
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 10px;
  text-align: center;
}

.card .stats-container {
  width: 100%;
  margin-top: 50px;
}

.card .stats {
  display: block;
  float: left;
  width: 33.333333%;
  text-align: center;
}

.card .stats:first-child {
  border-right: 1px solid #eeeeee;
}

.card .stats:last-child {
  border-left: 1px solid #eeeeee;
}

.card .stats h4 {
  font-weight: 300;
  margin-bottom: 5px;
}

.card .stats p {
  color: #777777;
}

/*      Just for presentation        */

/*       Fix bug for IE      */

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {

  .front,
  .back {
    -ms-backface-visibility: visible;
    backface-visibility: visible;
  }

  .back {
    visibility: hidden;
    -ms-transition: all 0.2s cubic-bezier(0.92, 0.01, 0.83, 0.67);
  }

  .front {
    z-index: 4;
  }

  .card-container:not(.manual-flip):hover .back,
  .card-container.manual-flip.hover .back {
    z-index: 5;
    visibility: visible;
  }
}

.react-tabs__tab:focus::after {
  display: none !important;
}

.react-tabs__tab:focus {
  box-shadow: none !important;
}

.ContractAddress {
  color: #ffffff;
  z-index: 100000;
  text-align: center;
  position: fixed;
  width: 100%;
  background: #3a127a;
  border-bottom: 1px solid #dedede52;
  padding: 5px 0px;
  top: 0px;
  transition: all 0.3s ease;
}

.navbar {
  transform: translateY(0px);
}

.navbar-sticky .navbar-brand,
.navbar-sticky .navbar ul {
  transform: translateY(0px) !important;
}

.navbar-sticky .ContractAddress {
  transform: translateY(-50px);
}

@media screen and (max-width: 800px) {
  .ContractAddress {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .p-5 {
    padding: 20px 12px !important;
  }

  .p-5 label {
    margin: 0px !important;
  }

  .collaborationPage {
    padding-top: 65px;
  }
}

@media screen and (min-width: 1200px) {
  .form-subscribe {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

@media screen and (min-width: 800px) {

  .navbar ul,
  .navbar-brand {
    transition: all 0.2s ease;
    transform: translateY(30px);
  }
}

.navigation.navbar-sticky {
  min-height: 60px;
}

.DropList-btn {
  background-color: #7a439e;
  background-image: url("../../img/dots.png") !important;
  background-size: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #dedede1a !important;
  padding: 0px;
  box-shadow: none !important;
}

.DropList-btn:hover,
.DropList-btn:focus,
.DropList-btn:active {
  background-color: #5f285f !important;
}

.top-nft-card,
.bottom-nft-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.NFT-Title {
  color: #4c277c !important;
  padding: 3px 10px 5px 0px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.owned .NFT-Title {
  color: #ffffff !important;
}

.bottom-nft-card .type {
  cursor: default !important;
  background-color: #7a439e;
  border-radius: 5px;
  border: 1px solid hsla(0, 0%, 87.1%, 0.10196078431372549) !important;
  font-size: 11px;
  padding: 0px 7px;
  line-height: 25px;
}

.card .dropdown-menu {
  transform: translate3d(-130px, 33px, 0px) !important;
  border-radius: 6px;
  padding: 7px 0px;
  border: none !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
}

.dropdown-item {
  color: #4c277c;
  font-weight: 600;
  padding: 2px 12px;
  font-size: 15px;
}

.dropdown-item:hover {
  background-color: #7a439e;
  color: #fff;
}

.inactive {
  font-weight: 400;
  color: #b8afaf;
  cursor: default;
  pointer-events: none;
}

.owned .nbrOwned {
  display: inline-block;
}

.nft-page {
  padding: 0px;
}

.rounded {
  border-radius: 20px !important;
}

// New NFT Card //

.container.nft-page {
  max-width: 1530px;
}

.smallinstruction {
  font-size: 10px;
  padding-left: 8px;
  margin: 5px 0px;
}

.smallinstruction span {
  font-weight: 600;
  cursor: pointer;
  background: #20195e;
  border-radius: 4px;
  padding: 2px 4px;
}

.nft-page .container-fluid {
  padding: 70px 0px 0px 0px;
}

.my-collection {
  max-width: 1200px !important;
  margin: 0 auto;
}

.sale-page {
  max-width: 1200px !important;
  margin: 0 auto;
}

.react-tabs__tab-list,
.header-center {
  max-width: 1120px;
  margin: 0 auto !important;
}

.colNFT {
  padding: 20px 0px;
  position: relative;
  vertical-align: inherit;
  max-width: 100%;
  min-height: 0px;
  min-width: 0px;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
  -moz-box-align: stretch;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: initial;
  margin-left: -20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.auction-page {
  max-width: 1200px !important;
  margin: 0 auto;
}

.auction-title h4 {
  font-size: 20px;
}

.auction-title .available-amount {
  font-size: 17px;
}

.outerNFT {
  display: flex;
  flex-direction: column;
  min-width: 30%;
  padding-left: 20px;
}

.nft-card img {
  max-height: 330px;
}

.nft-card {
  cursor: pointer;
  border: 1px solid #dedede;
  border-radius: 15px;
  margin-bottom: 15px;
}

.Modal video {
  height: 418px !important;
  max-width: 420px;
  width: auto !important;
  margin-top: 12px;
  border-radius: 10px;
}

/* .sale-page {
    background: rgba(255, 255, 255, 0.575);
    border: 1px solid #dedede;
    border-radius: 15px;
} */

/* .sale-page .card.nft-quest{
    box-shadow: none !important;
} */

.igmrKQ {
  position: relative;
  -moz-box-flex: 1;
  flex: 1 0 auto;
  vertical-align: inherit;
  max-width: 100%;
  min-height: 0px;
  min-width: 0px;
  flex-direction: column;
  display: flex;
  -moz-box-align: stretch;
  align-items: stretch;
}

.fIPEUP {
  vertical-align: inherit;
  max-width: 100%;
  min-height: 0px;
  min-width: 0px;
  flex-shrink: 0;
  flex-direction: column;
  flex-basis: auto;
  display: flex;
  -moz-box-align: stretch;
  align-items: stretch;
}

.bMdeOs {
  -moz-box-align: center;
  align-items: center;
  -moz-box-flex: 1;
  flex-grow: 1;
  flex-direction: column;
  -moz-box-pack: center;
  justify-content: center;
}

.kMTXgk {
  display: flex;
  position: relative;
  width: 100%;
  padding: 5px 0px 10px 0px;
  height: 300px;
  cursor: pointer;
}

.lazy-load-image-background {
  display: flex;
  flex-flow: column nowrap;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.dUxFWE {
  display: flex;
  flex-flow: column nowrap;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

/* 
#react-tabs-1 .not-listed {
  background-image: linear-gradient(180deg, #a371af 0, #521640 100%);
  opacity: 0.4;
  transition: all 0.3s ease;
}

#react-tabs-1 .not-listed:hover {
  opacity: 1;
  background-image: none;
}
 */
 
.fqbJCS {
  height: auto;
  max-height: 100%;
  max-width: 100%;
}

.nft-player div {
  width: 100% !important;
  height: auto !important;
}

video {
  margin: 2px 0px 12px 0px;
  border-radius: 10px;
}

.sale-details {
  height: 500px;
}

.nft-details {
  min-height: 500px;
  background: rgba(255, 255, 255, 0.84);
}

.nft-details {
  color: #482188;
}

.nft-details h3 {
  color: #482188;
  font-size: 23px;
  font-weight: 600;
}

.nft-details h4 {
  font-size: 17px;
  font-weight: 600;
}

.creator {
  background-image: linear-gradient(180deg, #85357e 0, #163075 100%);
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.creator img {
  filter: invert(1);
}

.creatorAddress {
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.description {
  color: #482188;
  font-size: 17px;
}

.nft-details-content {
  padding: 30px 50px 0px 50px;
}

.TooltipIcon img {
  margin: 3px 7px 0px 0px;
  box-shadow: none !important;
}

.owned .TooltipIcon img {
  filter: contrast(100%) brightness(700%);
}

.left {
  max-width: 85%;
  display: flex;
  flex-direction: row;
}

.bring-to-front {
  margin: 0px !important;
  padding: 50px 0px !important;
}

.sticky {
  position: sticky !important;
  position: -webkit-sticky;
  top: 0 !important;
}

.sticky-col {
  display: inline-block;
}

.sticky-outer-wrapper .listing,
.sticky-outer-wrapper .listing .who strong,
.sticky-outer-wrapper .listing .what {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.sticky-outer-wrapper .listing .what {
  font-size: 15px !important;
  line-height: 20px !important;
}

.details-column {
  background: #ffffffe5;
  border-radius: 7px;
  padding: 0px;
}

.details-column .react-tabs__tab-list {
  padding: 2px 10px 0px 10px;
}

.details-column .react-tabs__tab {
  background: none !important;
  border-radius: 0px !important;
  color: #bfbbc5 !important;
  font-weight: 600;
  padding: 4px 10px 3px 10px !important;
  margin: 0px !important;
  border-bottom: 3px solid #ffffff00 !important;
}

.details-column .react-tabs__tab .explorerLink {
  transform: translateY(-1px);
}

.details-column .react-tabs__tab--selected {
  color: #40296b !important;
  border-bottom: 3px solid #3b0e75d7 !important;
}

.details-column .react-tabs__tab-panel {
  margin: 0px !important;
  padding: 6px 0px 0px 4px;
}

.details-column .transaction {
  background-image: linear-gradient(-90deg, #85357e -1%, #163075 150%);
  border: none;
  color: #ffffff !important;
}

.details-column .transaction-content,
.details-column .transaction-content span {
  color: #ffffff !important;
}

.details-column .transaction-content img {
  filter: invert(1);
}

.number-owner {
  font-size: 13px;
  font-weight: 400;
}

.countdown {
  font-size: 15px !important;
  font-weight: 300;
}

.countdown span {
  font-weight: 600;
  font-size: 17px !important;
}

.auction-tx span {
  padding: 0px;
  margin: 0px;
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
  max-width: 230px;
}

.auction-tx .who {
  transform: translateY(-5px);
  display: flex;
}

iframe {
  display: none !important;
}

.neutral.btn-primary {
  background: none;
  border: none;
  color: #333 !important;
  padding: 0px;
  box-shadow: none !important;
  font-size: 18px;
}


.offcanvas-header {
  padding: 10px 26px 0px 20px;
}

.offcanvas-body {
  padding-top: 0px;
}

.offcanvas {
  border: 1px solid #bcbdc2;
  -webkit-box-shadow: rgba(100, 100, 111, .2) 0 7px 29px 0;
  -moz-box-shadow: rgba(100, 100, 111, .2) 0 7px 29px 0;
  box-shadow: rgba(100, 100, 111, .2) 0 7px 29px 0;
  border-radius: 15px 0 0 15px;
  -moz-border-radius: 15px 0 0 15px;
  -webkit-border-radius: 15px 0 0 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 10px;
}

.offcanvas-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.settings-box-small {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  margin-bottom: 15px;
}

.settings-box-small h5 {
  font-size: 15px;
  margin: 0px;
  display: flex;
}

h5 .svg-inline--fa {
  margin: 4px;
}

.settings-box-small h5 span {
  padding: 5px 0px 0px 4px;
}

.settings-box-small.account {
  display: flex;
}

.settings-box-small.account img {
  width: 60px;
  margin-right: 10px;
  border-radius: 10px;
}

.settings-box-small.account h5 {
  font-size: 20px;
}

.navbar-secondary {
  border-radius: 6px;
  padding: 5px 5px 3px 5px !important;
  background-color: rgba(255, 255, 255, 0.315) !important;
}

.navbar-secondary .subbutton {
  border: none !important;
  border-radius: 6px !important;
  transform: translateY(-1px);
  padding: 6px 10px;
  font-weight: 500;
  color: #4a2e6e !important;
  background-image: linear-gradient(180deg, #ffffff 0, #ffffff 100%) !important;
  margin-right: 5px;
  cursor: pointer;
}

.navbar-flex {
  display: flex;
  flex-direction: row !important;
  width: 100%;
  justify-content: space-between;
}

.navbar-flex div {
  display: inline-block;
}

.navbar-secondary .navbar-nav .nav-link {
  border: none !important;
  border-radius: 6px !important;
  transform: translateY(-1px);
  padding: 6px 10px;
  font-weight: 500;
  color: #fcf6fb !important;
  background-image: linear-gradient(180deg, #662166 0, #4a2e6e 100%) !important;
  margin-right: 5px !important;
  cursor: pointer;
}

.navbar-secondary {
  position: relative;
  z-index: 9999999 !important;
  width: 98%;
}

.dropdown-menu {
  display: none !important;
}

.dropdown-menu.show {
  display: inline-block !important;
}

.navbar-secondary .dropdown-menu {
  transform: translate3d(0px, 0px, 0px) !important;
  border-radius: 6px;
  padding: 7px 0px;
  border: none !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
}

.colNFT {
  z-index: 10 !important;
  position: relative !important;
}